// interface iInvestmentRecord {
//     class: string;
//     bucket: string,
//     taxSavings: boolean,
//     amount: Number,
//     dateOfInvestment: Date
// }

export default class InvestmentRecord {
    public investmentClass!: string;
    public constant!: string;
    public bucket!: string;
    public taxSavings!: boolean;
    public amount!: number;
    public dateOfInvestment!: Date;
    constructor(
        investmentClass: string,
        bucket: string,
        taxSavings: boolean,
        amount: number,
        dateOfInvestment: Date
    )
    {
        this.investmentClass = investmentClass;
        this.bucket = bucket;
        this.taxSavings = taxSavings;
        this.amount= amount;
        this.dateOfInvestment = dateOfInvestment;
        this.constant = "Investment";
    }
    [key: string]: string | boolean | Date | number;
}