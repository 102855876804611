//import Chart from "../components/chart";
import InvestmentRecord from "./InvestmentRecord";
import InvestmentValue from "./InvestmentValue";


export default class Views {
    public InvestmentRecords: Array<InvestmentRecord>;
    public InvestmentValues: Array<InvestmentValue>;
    constructor(investmentRecords: Array<InvestmentRecord>,
        investmentValue: Array<InvestmentValue>) {
        this.InvestmentRecords = investmentRecords;
        this.InvestmentValues = investmentValue;
        this.InvestmentByClass = this.InvestmentByClass.bind(this);
        this.InvestmentByBucket = this.InvestmentByBucket.bind(this);
        this.ValueByBucket = this.ValueByBucket.bind(this);
        this.RecordToMap = this.RecordToMap.bind(this);
    }

    groupBy: string = 'investmentClass';

    AllInvestments = (): Map<string, Array<ChartObect>> => {
        this.groupBy = 'constant';
        return this.InvestmentRecords.reduce<Map<string, Array<ChartObect>>>
            (this.RecordToMap, new Map<string, Array<ChartObect>>());

    }

    InvestmentByClass = (): Map<string, Array<ChartObect>> => {
        this.groupBy = 'investmentClass';
        return this.InvestmentRecords.reduce<Map<string, Array<ChartObect>>>
            (this.RecordToMap, new Map<string, Array<ChartObect>>());

    }

    InvestmentByBucket = (): Map<string, Array<ChartObect>> => {
        this.groupBy = 'bucket';
        return this.InvestmentRecords.reduce<Map<string, Array<ChartObect>>>
            (this.RecordToMap, new Map<string, Array<ChartObect>>());

    }


    RecordToMap = (acc: Map<string, Array<ChartObect>>, currentValue: InvestmentRecord):
        Map<string, Array<ChartObect>> => {
        let returnObject: Map<string, Array<ChartObect>> = acc;

        let mappedArray: Array<ChartObect> | undefined = returnObject.get(currentValue[this.groupBy].toString());

        if (mappedArray === undefined) {
            returnObject.set(currentValue[this.groupBy].toString(), new Array<ChartObect>({ x: currentValue.dateOfInvestment, y: currentValue.amount }));
        }
        else {
            mappedArray.push({ x: currentValue.dateOfInvestment, y: mappedArray[mappedArray.length - 1].y + currentValue.amount });
        }
        return returnObject;
    }

    ValueByBucket = (): Map<string, Array<ChartObect>> => {
        return this.InvestmentValues.reduce<Map<string, Array<ChartObect>>>
            (this.ValueToMap, new Map<string, Array<ChartObect>>());
    }


    NetWorth = (): Array<{ chartObject: ChartObect, valueDetail: Map<string, number> }> => {
        return this.InvestmentValues.reduce<Array<{ chartObject: ChartObect, valueDetail: Map<string, number> }>>
            (this.valueSum, new Array<{ chartObject: ChartObect, valueDetail: Map<string, number> }>());
    }

    valueSum = (acc: Array<{ chartObject: ChartObect, valueDetail: Map<string, number> }>, currentValue: InvestmentValue): { chartObject: ChartObect, valueDetail: Map<string, number> }[] => {
        let returnObject = acc;
        let chartObject: ChartObect = { x: currentValue.dateofRecord, y: currentValue.amount };
        if (acc.length === 0) {
            returnObject.push(
                {
                    chartObject: chartObject,
                    valueDetail: new Map<string, number>().set(currentValue.bucket, currentValue.amount)
                }
            );
        }
        else {
            // console.log("just before error");
            // console.log(returnObject.length);
            let prev_Value = returnObject[returnObject.length - 1];
            let prev_bucket_value = prev_Value.valueDetail?.get(currentValue.bucket);

            let new_value: { chartObject: ChartObect, valueDetail: Map<string, number> } =
            {
                chartObject: { x: currentValue.dateofRecord, y: currentValue.amount },
                valueDetail: new Map<string, number>()
            };
            if (prev_bucket_value === undefined) {
                new_value.chartObject.y = prev_Value.chartObject.y + chartObject.y;

            }
            else {
                // console.log("prev not undefined");
                // console.log(prev_Value.valueDetail.keys.length);

                // for (var i in prev_Value.valueDetail.keys) {

                //     new_value.valueDetail.set(i, prev_Value.valueDetail.get(i)!);
                // }
                new_value.chartObject.y = prev_Value.chartObject.y - prev_bucket_value + chartObject.y;
            }
            prev_Value.valueDetail.forEach((number: number, key: string, mapObj: Map<string, number>) => {
                new_value.valueDetail.set(key, number);
            });
            new_value.valueDetail.set(currentValue.bucket, currentValue.amount);
            returnObject.push(new_value);
        }

        return returnObject;
    }


    ValueToMap = (acc: Map<string, Array<ChartObect>>, currentValue: InvestmentValue):
        Map<string, Array<ChartObect>> => {
        let returnObject: Map<string, Array<ChartObect>> = acc;

        let mappedArray: Array<ChartObect> | undefined = returnObject.get(currentValue.bucket);

        if (mappedArray === undefined) {
            console.log("first");
            returnObject.set(currentValue.bucket, new Array<ChartObect>({ x: currentValue.dateofRecord, y: currentValue.amount }));
        }
        else {
            console.log("push");
            mappedArray.push({ x: currentValue.dateofRecord, y: currentValue.amount });
        }
        return returnObject;
    }

}