import { GoogleSpreadsheet } from "google-spreadsheet";
import InvestmentRecord from "./InvestmentRecord";
import Views from "./Views";
import moment from "moment";
import InvestmentValue from "./InvestmentValue";

export default class SheetAdapter {
    protected doc: any
    protected Client_Email: string;
    protected Private_Key: string;

    constructor(SheetId: string,
        client_Email: string,
        private_Key: string) {
        this.doc = new GoogleSpreadsheet(SheetId);

        this.Client_Email = client_Email;
        this.Private_Key = private_Key;
        this.readInvestments = this.readInvestments.bind(this);
        this.readInvestmentValue = this.readInvestmentValue.bind(this);
        this.isValid = this.isValid.bind(this);
        this.doc.useServiceAccountAuth({
            client_email: this.Client_Email,
            private_key: this.Private_Key,
        });
    }

    isValid = async (): Promise<boolean> => {
        let retrunValue: boolean = false;
        try {
            if (this.doc) {
                await this.doc.loadInfo();
                retrunValue = true;
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            return retrunValue;
        }
    }
    isInvestmentRecord(obj: any): obj is InvestmentValue {
        return true;
    }
    read = async (): Promise<Views | any> => {
        var investmentRecords = await this.readInvestments();
        var investmentValues = await this.readInvestmentValue();
        return new Views(investmentRecords, investmentValues);
    }

    readInvestments = async (): Promise<InvestmentRecord[] | any> => {
        try {
            console.log('read investsmets');

            // loads document properties and worksheets
            //await this.doc.loadInfo();
            const sheet = this.doc.sheetsByIndex[0];
            let investmentRecords: InvestmentRecord[] = [];
            const rows = await sheet.getRows();
            rows.forEach((row: any) => {
                investmentRecords.push(new InvestmentRecord(row.Class,
                    row.Bucket,
                    row.TaxSavings,
                    +row.EffectiveAmount,
                    moment(row.DateOfInvestment, 'DD/MM/YYYY').toDate()
                ));
            });

            investmentRecords = investmentRecords.sort((a: InvestmentRecord, b: InvestmentRecord) => {
                return a.dateOfInvestment.getTime() - b.dateOfInvestment.getTime();
            });

            console.log(investmentRecords)
            //this.views = new Views(investmentRecords);
            return investmentRecords;

        } catch (e) {
            return e;
        }
    }

    readInvestmentValue = async (): Promise<InvestmentValue[] | any> => {
        try {
            console.log('reading value');
            // this.doc.useServiceAccountAuth({
            //     client_email: this.Client_Email,
            //     private_key: this.Private_Key,
            // });
            // // loads document properties and worksheets
            // await this.doc.loadInfo();
            const sheet = this.doc.sheetsByIndex[1];
            let investmentValue: InvestmentValue[] = [];
            const rows = await sheet.getRows();
            rows.forEach((row: any) => {
                investmentValue.push(new InvestmentValue(row.Class,
                    row.Bucket,
                    +row.Value,
                    moment(row.DateofRecord, 'DD/MM/YYYY').toDate()
                ));
            });

            investmentValue = investmentValue.sort((a: InvestmentValue, b: InvestmentValue) => {
                return a.dateofRecord.getTime() - b.dateofRecord.getTime();
            });

            console.log(investmentValue)
            return investmentValue;

        } catch (e) {
            return e;
        }
    }
}

