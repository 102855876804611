import { Line } from "react-chartjs-2";

import React from "react";
import Views from "../lib/Views";
import moment from "moment";

//Charts.defaults.line.spanGaps = true

const options = {
  type: "line",
  spangaps: true,
  steppedLine: "middle",
  pointRadius: 0,
  //showLines: false,
  scales: {
    xAxes: [{
      type: 'time',
      time: {
        unit: 'month',
        displayFormats: {
          quarter: 'MMM YYYY'
        }
      }
    }
    ]
  }
};

type mychartdata = {
  fill: boolean,
  label: string,
  data: any,
  borderColor: string,
  backgroundColor: string,
  lineTension: number,
  pointRadius: number,
};

export default class Chart extends React.Component<{ View: Views }> {

  // constructor(props: { View: Views }) {
  //   super(props);
  // }

  getDataset = (View: Views): { datasets: mychartdata[] } => {
    let datasets: { datasets: mychartdata[] } = { datasets: [] };
    View.AllInvestments().forEach((value: ChartObect[], key: string, map: Map<string, ChartObect[]>) => {
      datasets.datasets.push({
        label: key,
        data: value,
        fill: false,
        borderColor: "navy",
        backgroundColor: this.random_rgba(),
        lineTension: .8,
        pointRadius: 2,
      })
    });

    let chartValues2: ChartObect[] = new Array<ChartObect>();
    let chartValues: Array<ChartObect> = new Array<ChartObect>();
    //  View.NetWorth().forEach((value: { chartObject: ChartObect, valueDetail:  Map<string, number>})  => {
    //    console.log("eeeeee");
    //    chartValues2.push(value.chartObject);
    //  });
    console.log("here");
    if (View.NetWorth().length > 0) {
      View.NetWorth().forEach(element => chartValues2.push(element.chartObject));

      console.log(chartValues2);
      chartValues.push({ x: chartValues2[0].x, y: 2067 });
      chartValues.push({ x: moment("31/07/2018", 'DD/MM/YYYY').toDate(), y: 3000 });
      // console.log(chartValues2);
      // console.log(chartValues);
      datasets.datasets.push({
        label: "NetWorth",
        data: chartValues2,
        fill: false,
        borderColor: "green",
        backgroundColor: this.random_rgba(),
        lineTension: .8,
        pointRadius: 3,
      });
    }
    //console.log(datasets.datasets);


    // View.InvestmentByBucket().forEach((value: ChartObect[], key: string, map: Map<string, ChartObect[]>) => {
    //   datasets.datasets.push({
    //     label: key,
    //     data: value,
    //     fill: false,
    //     borderColor: this.random_rgba(),//"#742774",
    //     backgroundColor: this.random_rgba(),
    //     lineTension: .8
    //   })
    // });

    // View.ValueByBucket().forEach((value: ChartObect[], key: string, map: Map<string, ChartObect[]>) => {
    //   datasets.datasets.push({
    //     label: key,
    //     data: value,
    //     fill: false,
    //     borderColor: this.random_rgba(),//"#742774",
    //     backgroundColor: this.random_rgba(),
    //     lineTension: .8
    //   })
    // });

    // console.log("getDataset Called");
    // console.log(datasets);
    return datasets;
  }
  random_rgba = (): string => {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
  }
  render() {
    return (
      <div className="w-full md:w-5/6">
        <Line data={this.getDataset(this.props.View)} options={options} />
        {/* height={100} */}
      </div>
    );
  }
}

  // export default function Chart() {
  //   return (
  //     <div >
  //       <Line data={data} options={options} />
  //     </div>
  //   );
  // }