import React, { Component } from 'react';
import Chart from './chart';
import SheetAdapter from '../lib/Core';
import Views from '../lib/Views';


type adhoc = {
  View: Views;
}

class InvestmentSummary extends Component<{ data: GAPICredential }, adhoc> {
  constructor(props: { data: GAPICredential }) {
    super(props);
    this.state = { View: new Views([], []) };
  }
  componentDidMount() {
    const sheet = new SheetAdapter(this.props.data.SpreadSheet_Id, this.props.data.Client_Email, this.props.data.Private_Key);
    sheet.isValid()
      .then((response: boolean) => {
        sheet.read()
          .then((response: Views) => {
            console.log(response);
            this.setState({ View: response });
          });
      });

  }

  render() {

    return (
      <div>
        Investment Summary
        <Chart View={this.state.View} />
      </div>
    );
  }
}


export default InvestmentSummary;