import React from 'react';
import './App.css';
import InvestmentSummary from './components/InvestmentSummary';
import SheetAdapter from './lib/Core';


// const SPREADSHEET_ID = 'spread sheet id';

// const CLIENT_EMAIL = 'client email';
// const PRIVATE_KEY = 'private key';


const SPREADSHEET_ID = '15l0qLP8VA4Mq1GVayzZ60hCVVxYhkR_j-Z8RbBuH_3g';

const CLIENT_EMAIL = 'net-worth-test@net-worth-1610238338384.iam.gserviceaccount.com';
const PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDibNWdgxnu2IxT\nyzoE8y2pH+wHom7LkDNSigKA76qpizNBfwIyuMgdp85pHQvg2UiXlTD5DILLXYrx\n5nJqfKUy2GW3RXEdkvoEnlEhPj+Apx6CeGbhlql3R/ZBa81PKNOgGpbCJdCAKEF+\nAZtO37rxzM7sLqP8vKkD4zEXNpmkKGLnHLHMe6mclsIyN/PyZ6b2HKuJfkv8XLbq\nq6iktUifppC6JyiZJPpyniZycK0JLXR5hsH7hxc/6DonZe5elFU4Gd6yTjcIAp0w\niVsN2QEwq0vj66prhOFbV2wb5sevUgOEDxfFqQH9bb8t1EDw1Q0Hmb3rDFaE62Qs\npo4N+7IZAgMBAAECggEAFgoUMmJjFTK2mQbqcTYm6wQIjlcvG+kft8BnfTum490g\n6naFhSCjmPBfKO/a+sJHnLqYw1smhYtHxaNB3o/eqB/0aaYMnD100NuEp9zWY0xR\nDc/QqtNmkC3c4FIREEwX6kkAROpXnMveURiwsHkaSiQ+fXKOQ4DtHivgeMMXqijV\n1Tm73HyipH9K8X97oe5RuQxWIGzzXMk3F+w+Fh858sOJaxXLLkjgzuYHIbdDmswX\nvXS3LBEXi0VQyXQs2sixywSm6w77Q3hIKYVpDraNtVtVN+6Ye5qWhaIPJJKI1muD\nZhsC+5vTW3766QguHUMtpAD7sCn/WqqVi+aiwvsxAQKBgQDzfg7evxNI/QpV+pql\nn8hSrcm0NAMsOUENHOckVZexP4fd/cFJLbh/3juR3rWLp8rm9iujarwdYqOklLTa\nd4nSKuwQggkdwoqq9UeRGIo2yeVSxnwdEczzF2IwM8vZ5JjYmP3RjOonbuvAZbHW\nddEcqHay30FcpgADNqdgW6OXGQKBgQDuDlcntvX9OUXDvwEH78WaWKo+a5hRWXNI\nrnUDG6kF9ijvLZ5mxypmStoPEmX2/BOea9sm4aViTOrt9eWU56f2X8aJQBIKDwYu\nC2C/Kb/XrvErxk3SDnw519eQ8uNldsunuweE2eOHm7ZdY87/fSVIFd9657AMKKwn\nbcpMrZNTAQKBgQDCK9Zj3u/cFp21bsEALukqtZfR8mBNVeMAN/pDSVZMbKNGbeND\nHgixP2E1s+oGUmpFwC2Qhb85l71zs3p9dTIcvUm+6FOovcijCDJoDK+7fUk4tzum\nWtmbSRxt4ABB5D/XCjnub3cStyudI/urUAzP06/EeLDA10fPSDg1aR6NkQKBgEdD\njb263MrwcT0XGdTpZ2s8LcK/7Po1X/ibGTZT+nZpaXmoW2oDJ2M19y9Z36JfdfQP\n76wlEqBpzE5uNUstSi/MHiAKSRwvwTbZex3e/qovmczz8Wn3oiKixcqDm6VNOcw9\nhhO0MpPu/lnYvifnq9h3i848O4x4RIkNKelocNkBAoGBALEjlzblu+0Ams6xDwyc\njEx7AD1rpV+mhEMQDfUAZi6EirJEygFiTmsek8OV2S7cJAi7ttu+1ID4ElhX+3Zf\n5BG+elFydocBb76j3x7N33PHQUJfwU5OYFd/3u0miBhTwBD0Gs3so87Fh/e4fK/N\nrGCYeufgKIszgRAyc2S7SbF0\n-----END PRIVATE KEY-----\n"


type AppState = {
  Client_Email: string,
  SpreadSheet_Id: string,
  Private_Key: string,
  isValid: boolean
}

class App extends React.Component<{}, AppState> {
  constructor(props: {}, state: AppState) {
    super(props);
    this.state = {
      isValid: true,
      Client_Email: CLIENT_EMAIL,
      SpreadSheet_Id: SPREADSHEET_ID,
      Private_Key: PRIVATE_KEY
    };

    this.handleChangeClientId = this.handleChangeClientId.bind(this);
    this.handleChangeAPIKey = this.handleChangeAPIKey.bind(this);
    this.handleChangeDocId = this.handleChangeDocId.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleChangeClientId(event: React.ChangeEvent<HTMLInputElement>) {
    await this.setState({ Client_Email: event.target.value });
    await this.setIsValid();
  }

  async handleChangeAPIKey(event: React.ChangeEvent<HTMLTextAreaElement>) {
    await this.setState({ Private_Key: event.target.value });
    await this.setIsValid();
  }

  async handleChangeDocId(event: React.ChangeEvent<HTMLInputElement>) {
    await this.setState({ SpreadSheet_Id: event.target.value });
    await this.setIsValid();
  }
  handleSubmit(event: React.FormEvent) {
    alert('A name was submitted: ' + this.state.Private_Key);
    event.preventDefault();
  }
  async setIsValid() {
    try {
      console.log(this.state.SpreadSheet_Id);
      let sheet = new SheetAdapter(this.state.SpreadSheet_Id, this.state.Client_Email, this.state.Private_Key);
      sheet.isValid()
        .then((value: boolean) => {
          console.log(value);
          this.setState({ isValid: value })
        })
        .catch((reason: any) => console.log(reason));
    }
    catch (e) {

    }
  }

  render() {
    let investmentSummary;
    if (this.state.isValid) {
      console.log("InvestSummary added");
      investmentSummary = <InvestmentSummary data={this.state} />
    }
    else {
      investmentSummary = <div />;
    }
    return (
      <div className="">

        <nav className="border-2">

          <div className="flex flex-wrap items-center">
            <div className="flex flex-shrink md:w-1/3 justify-center md:justify-start text-white">

              <svg height="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-14a3 3 0 00-3 3v2H7a1 1 0 000 2h1v1a1 1 0 01-1 1 1 1 0 100 2h6a1 1 0 100-2H9.83c.11-.313.17-.65.17-1v-1h1a1 1 0 100-2h-1V7a1 1 0 112 0 1 1 0 102 0 3 3 0 00-3-3z" clip-rule="evenodd" />
              </svg>
            </div>
            <div className="flex w-full pt-2 content-centre justify-between md:w-2/3 md:justify-end">
              <div className="text-gray-50">Net worth</div>
              <svg height="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd" />
              </svg>
            </div>
          </div>

        </nav>
        {/* <GoogleLogin ClientId={this.state.ClientId}/> */}
        <form onSubmit={this.handleSubmit}>
          <label>
            Client Email:
            <input type="text" value={this.state.Client_Email} onChange={this.handleChangeClientId} />
          </label>
          <label>
            Private Key:
            <textarea value={this.state.Private_Key} onChange={this.handleChangeAPIKey} />
          </label>
          <label>
            Doc Id:
            <input value={this.state.SpreadSheet_Id} onChange={this.handleChangeDocId} />
          </label>
          <input type="submit" value="Submit" />
          {investmentSummary}

        </form>
        {/* <GoogleSheetsProvider >
          
        </GoogleSheetsProvider> */}
        {/* <Chart/> */}
      </div>
    );
  }
}
export default App;