

export default class InvestmentValue {
    public investmentClass!: string;
    public bucket!: string;
    public amount!: number;
    public dateofRecord!: Date;
    constructor(
        investmentClass: string,
        bucket: string,
        amount: number,
        dateofRecord: Date
    )
    {
        this.investmentClass = investmentClass;
        this.bucket = bucket;
        this.amount= amount;
        this.dateofRecord = dateofRecord;
    }
    [key: string]: string | Date | number;
}